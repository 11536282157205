/**
 * Synapse - adaptive theme
 */
/** Header */
.region-menu-main {
  padding: 15px 0 0;

  #block-adaptive-menu-main {
    background: #008791;
  }
}

.nav .active a {
  color: #fff !important;
  border-bottom: 1px solid transparent;
}

#small-menu {
  text-align: center;
  padding: 0;
  clear: both;
  border-top: #777 solid 1px;
  background: #008791;

  &.container {
    @media(max-width: 767px) {
      max-width: none;
      width: auto;
    }
  }
}

.small-menu__inner {
  padding: 10px 0;
}

#small-menu a {
  padding: 0;
  display: inline-block;
}

#small-menu .active a {
  color: #000;
}

#small-menu .nav {
  display: block;
}

#small-menu .classic-menu-dropdown .menu {
  position: static;
  float: none;
  display: none;
  border-radius: 0;
}

#small-menu .menu-item {
  text-align: center;
  padding: 10px 0;
}

#small-menu .classic-menu-dropdown>.menu-item {
  overflow: hidden;
}

#small-menu .classic-menu-dropdown>a {
  display: block;
  max-width: calc(100% - 50px);
  margin: 0 auto;
}

#small-menu .classic-menu-dropdown .menu-dropdown-trigger {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  max-height: 100%;
  font-size: 21px;
  display: block;
  text-align: center;
  padding: 7px 0;
  cursor: pointer;
}

#small-menu .menu-item--is-open {
  background-color: #0188ca;
}

#small-menu .menu-item--is-open>.menu-dropdown-trigger,
#small-menu .menu-item--is-open>a {
  color: #fff;
}

#small-menu .menu-item--is-open>.menu-dropdown-trigger {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
}

@import "header/header-full";
/*@import "header/menu-main";*/
/*@import "header/menu-small";*/
@import "header/togglers";

#header-full {
  .header-full-top {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .site-name {
      font-family: 'pf_din_text_cond_proregular';
      font-weight: 500;
      color: #008791;
      font-size: 21px;
      line-height: 25px;
      margin-left: 26px;
      max-width: 241px;
    }

    .address {
      font-family: 'pf_din_text_cond_proregular';
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      color: #494949;
      max-width: 217px;
    }

    .buttons {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    .phones {
      width: 370px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 7px;
    }

    .phone {
      font-family: 'pf_din_text_cond_proregular';
      font-weight: 500;
      font-size: 25px;
      line-height: 30px;
      color: #494949;
    }

    .btn-callme {
      border: 0;
      background-color: transparent;
      font-family: 'pf_din_text_cond_proregular';
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      text-decoration-line: underline;
      color: #008791;
    }
  }

}
