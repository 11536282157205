/**
Synapse - adaptive theme
.col-xs- <544px Extra small
.col-sm- ≥544px Small
.col-md- ≥768px Medium
.col-lg- ≥992px Large
.col-xl- ≥1200px Extra large
Gutter width	1.875rem / 30px (15px on each side of a column)
*/
/** Header */
header {}

.region-menu-main {
  clear: both;
}

/** Menu */
.nav a {
  text-decoration: none;
  color: #fff;
  font-family: 'pf_din_text_cond_proregular';
  font-weight: bold;
  font-size: 22px;
  line-height: 22px;
  border-bottom: 1px solid #fff;
  padding: 0;

  &:hover {
    color: #fff !important;
    border-bottom: 1px solid transparent;
    -webkit-transition: all 200ms linear;
    -moz-transition: all 200ms linear;
    -ms-transition: all 200ms linear;
    -o-transition: all 200ms linear;
    transition: all 200ms linear;
  }
}

.nav .active a {
  color: #fff !important;
  border-bottom: 1px solid transparent;
}

#header-onepage {
  padding: 9px 0 3px;
}

#header-full .nav-inline li {
  display: inline-block;
}

#header-full nav li {
  padding: 18px 35px;

  @media (max-width: 1199px) {
    padding-left: 25px;
    padding-right: 25px;
  }
}

#header-full .nav {
  justify-content: center;
}

/** On Scroll */
.appear-onscroll {
  top: -56px;
  opacity: 0;
  overflow: hidden;
  transition: all 0.6s;
}

@media (max-width: 992px) {
  .appear-onscroll {
    top: 0;
    opacity: 1;
    max-height: none;
  }

  #top {
    margin-top: 56px;
    display: block;
  }
}

/** Small */
#header-info {
  border-bottom: #777 solid 1px;
}

.header-info {
  &__inner {
    padding: 10px 0;
  }

  &__phones {
    display: flex;
    flex-direction: column;
  }

  &-phone {
    font-size: 1.25rem;
  }
}

.header-small__inner {
  padding: 5px 0 0;
}

#header-small .logo-small img {
  height: 41px;
  margin-bottom: 5px;
}

/** Full  */
#header-full {
  padding: 18px 0 0;
}

#header-full .logo {
  position: relative;
  z-index: 2;
}

#header-full .logo a {
  display: inline-block;
  text-decoration: none;
}

/** Toggler */
.nav-togglers {
  padding-top: 0;
}

.nav-toggler {
  position: relative;
  width: 44px;
  height: 40px;
  padding: 0;
  overflow: hidden;
  border-radius: 4px;
  border: 2px solid #0188ca;
  margin-left: 6px;
  color: #0188ca;
  background: none;
}

.nav-toggler:focus {
  outline: none;
}

.nav-toggler.collapsed {
  color: #444;
  border-color: #666;
}

.nav-toggler .icon-bar+.icon-bar {
  margin-top: 0;
}

.nav-toggler .icon-bar {
  position: absolute;
  height: 3px;
  transition: all 0.5s;
  background-color: #0188ca;
  display: block;
  border-radius: 1px;
}

.nav-toggler.collapsed .icon-bar {
  background-color: #666;
}

.nav-toggler .icon-bar-top {
  transform: rotate(-45deg);
}

.nav-toggler .icon-bar-bottom {
  transform: rotate(45deg);
}

/** Menu */
.nav-menu {}

.nav-menu .icon-bar {
  left: 5px;
  top: 17px;
  width: 30px;
}

.nav-menu .icon-bar-middle {
  opacity: 0;
  left: -30px;
  top: 17px;
}

.nav-menu.collapsed .icon-bar-middle {
  opacity: 1;
  left: 5px;
}

.nav-menu.collapsed .icon-bar-top {
  transform: rotate(0);
  top: 8px;
}

.nav-menu.collapsed .icon-bar-bottom {
  transform: rotate(0);
  top: 26px;
}

/** Info */
.nav-info {
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 20px;
}

.nav-info.collapsed {
  width: 41px;
}

.nav-info .icon-bar {
  left: 3px;
  top: 17px;
  width: 30px;
}

.nav-info.collapsed .icon-bar-top {
  transform: rotate(90deg);
  top: 8px;
  width: 3px;
  left: 17px;
}

.nav-info.collapsed .icon-bar-bottom {
  transform: rotate(90deg);
  top: 20px;
  width: 15px;
  left: 11px;
}

.main-menu {}

.main-menu .classic-menu-dropdown {
  position: relative;
}

.main-menu .classic-menu-dropdown ul {
  /*rtl:auto*/
  left: 0;
  min-width: 175px;
  position: absolute;
  z-index: 1000;
  display: none;
  float: left;
  list-style: none;
  text-shadow: none;
  padding: 10px 5px;
  background-color: #ffffff;
  margin: 0;
  border: 1px solid #eee;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}

.main-menu .classic-menu-dropdown ul li {
  line-height: 1.5em;
}

.main-menu .menu-dropdown-trigger {
  display: none;
}

.main-menu .classic-menu-dropdown:hover ul {
  display: block;
  position: absolute;
}

/*#small-menu .classic-menu-dropdown .menu {
    position: static;
    float: none;
    display: none;
    border-radius: 0;
}
#small-menu .menu-item {
    text-align: center;
}
#small-menu .classic-menu-dropdown>.menu-item {
    overflow: hidden;
}
#small-menu .classic-menu-dropdown>a {
    display: block;
    max-width: calc(100% - 50px);
    margin: 0 auto;
}
#small-menu .classic-menu-dropdown .menu-dropdown-trigger {
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    max-height: 100%;
    font-size: 21px;
    display: block;
    text-align: center;
    padding: 7px 0;
    cursor: pointer;
}
#small-menu .menu-item--is-open {
    background-color: #0188ca;
}
#small-menu .menu-item--is-open>a,
#small-menu .menu-item--is-open>.menu-dropdown-trigger {
    color: #fff;
}
#small-menu .menu-item--is-open>.menu-dropdown-trigger {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
}*/
